.slider_container {
    position: fixed;
    display: block;
    width: 100%;
    height: 100dvh;
    top: 0;
    background-color: var(--black);
    /*z-index: -2;*/
}

.overlay {
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    /*z-index: 100;*/
}

.pic {
    width: 100%;
    height: 100dvh;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*position: relative;*/
}

@media screen and (max-width: 767px) {
    .slider_welcome {
        top: 70%;
        justify-content: space-between;
        /*height: 60dvh;*/
    }
}
