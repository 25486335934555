.menu_wrapper {
    display: flex;
    height: 100%;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
}

.menu_parent {
    cursor: pointer;
}

.arrow_right {
    border: solid whitesmoke;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
    margin-bottom: 3px;
    transition: 0.2s all ease-in-out;
}

.menu_wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
}

.menu_wrapper ul li {
    position: relative;
    float: left;
    margin: 0;
    height: 35px;
}

.menu_wrapper ul li span {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: whitesmoke;
    letter-spacing: 2px;
    display: block;
    text-align: center;
    text-decoration: none;
    transition: all .5s ease;
    cursor: pointer;
}

.menu_wrapper ul li span:hover {
    color: whitesmoke;
}

.menu_wrapper ul li ul {
    float: left;
    left: -25px;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: -5px;
    background-color: rgba(var(--black_rgb), 0);
    visibility: hidden;
    transition: all .5s ease;
    z-index: 1;
    width: 80px;
}

.menu_wrapper ul li:hover > ul {
    visibility: visible;
    transition: all .5s ease;
    opacity: 1;
    top: 35px;
}

.menu_wrapper ul li ul li {
    width: 100%;
}

.menu_wrapper ul li:hover span > i {
    transform: rotate(45deg);
    transition: 0.2s all ease-in-out;
}

.scroll_background {
    background-color: var(--black);
}


@media screen and (max-width: 800px) {
    .menu_wrapper ul li ul {
        background-color: rgba(var(--black_rgb), 1);
    }
}