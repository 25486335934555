@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

:root {
    --black: #181818;
    --black_rgb: 24, 24, 24;
    --light_gray: #f4f4f4;
    --dark_gray: #c5c4c4;
    --orange: #ffbe0b;
    color: var(--light_gray);
}

button {
    border-radius: 2px;
    border-width: 0;
    box-sizing: border-box;
    color: var(--black);
    cursor: pointer;
    font-size: 100%;
    height: 44px;
    line-height: 1.15;
    margin: 12px 0 0;
    outline: none;
    overflow: hidden;
    padding: 0 25px;
    position: relative;
    text-align: center;
    letter-spacing: 1px;
}

h1, h2, h3, h4, h5 {
    letter-spacing: 0;
    text-align: center;
    /*font-family: 'Prata', sans-serif;*/
    font-family: 'Oswald', sans-serif;
    color: var(--light_gray);
}

h1 {
    font-weight: 400;
    letter-spacing: 0;
}

h2 {
    font-size: calc(28px + (32 - 28) * ((100vw - 767px) / (1920 - 767)));
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 20px;
    margin-top: 10px;
    z-index: 1;
}

h3 {
    color: var(--dark_gray);
    font-weight: 400;
    font-size: 19px;
    letter-spacing: -0.01em;
    margin-top: 0;
}

h4 {
    position: relative;
    color: #333;
    font-size: 18px;
    letter-spacing: .4px;
    margin: 0 0 6px;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
}

a {
    color: var(--dark_gray);
    text-decoration: none;
}

section {
    background-color: var(--light_gray);
    border-bottom: 2px solid var(--light_gray);
    padding: 30px 0;
    width: 100%;
    min-height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.app {
    width: 100%;
}

.card {
    background-color: var(--black);
    box-shadow: 10px 10px 15px -1px rgba(0, 0, 0, .87);
}

.cards_wrapper {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    flex-wrap: wrap;
    z-index: 1;
}

.swiper-button-next, .swiper-button-prev {
    color: var(--orange) !important;
}

.overlay {
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
    position: fixed;
    top: 0;
    left: 0;
}

iframe {
    margin-top: -5px;
}