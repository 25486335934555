.overlay {
    width: 100%;
    height: 100dvh;
    z-index: 100;
}

.slider_welcome {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    justify-content: space-between;
    height: 30dvh;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 1;
}