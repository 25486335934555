.barbers_container {
    max-width: 1200px;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 100%;
    margin-top: 30px;
}
.slider_container {
    padding: 20px 30px;
    margin-bottom: 20px;
    min-height: 520px;
}