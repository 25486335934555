:root {
    font-family: 'Inter', sans-serif;
}

.header_wrapper {
    width: 100%;
    max-width: 100vw;
    height: 80px;
    box-sizing: border-box;
    background-color: rgba(var(--black), 0);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    color: var(--light_gray);
    position: fixed;
    z-index: 2;
    transition: all 0.4s ease-in-out;
}

.header_wrapper.scroll {
    background-color: var(--black);
    box-shadow: 0 3px 10px -3px rgba(255,190,11,1);
}

.logo {
    background-image: url("./logo.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 240px;
    height: 70px;
}

.lang_switcher_wrapper {
    display: flex;
    gap: 25px;
}

.burger_button {
    display: none;
}

.insta_link {
    color: var(--light_gray);
}

@media screen and (max-width: 800px) {
    .header_wrapper {
        background-color: var(--black);
        gap: 5px;
        justify-content: space-between;
    }

    .logo {
        background-repeat: no-repeat;
        background-size: cover;
        width: 220px;
        height: 64px;
    }

    .burger_button {
        display: block;
    }
}