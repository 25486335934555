.welcome h1 {
    color: whitesmoke;
    font-size: calc(30px + (40 - 30) * ((100vw - 360px) / (1920 - 360)));
    /*font-size: 40px;*/
}

/*@media screen and (max-width: 767px){*/
/*    .welcome h1 {*/
/*        display: none;*/
/*    }*/
/*}*/